<template>
    <UHeader :links="links">
        <template #logo>
            <p class="tracking-tight">NomadRx</p>
        </template>
        <template #right>
            <UButton v-if="secondaryButton.show" :label="secondaryButton.label" :color="secondaryButton.color"
                :to="secondaryButton.to" :variant="secondaryButton.variant" />
            <UButton :label="primaryButton.label" :color="primaryButton.color" :to="primaryButton.to"
                :variant="primaryButton.variant" />
        </template>
        <template #panel>
            <UHeaderPopoverLinks :links="links" />
            <UDivider class="my-4" />
            <div class="mt-4 space-y-2">
                <UButton v-if="isAuthenticated" label="Sign out" icon="i-heroicons-arrow-left-end-on-rectangle"
                    @click="signOutUser" block variant="soft" />
                <UButton v-else label="Create account" to="/onboard" block variant="solid" size="xl" />
                <UButton v-if="!isAuthenticated" label="Sign in" to="/login" block variant="soft" size="xl" />

            </div>
        </template>
    </UHeader>
</template>
<script setup lang="ts">
const authStore = useAuthStore();
const { isAuthenticated, userStateReady, hasAccountAccess, hasPartnerAccess } = storeToRefs(authStore);

const { signOutUser } = useFirebaseAuth();
const { isMobile } = useDevice()

const route = useRoute();
console.log('route:', route)

const onboardStore = useOnboardStore();
const { partner } = storeToRefs(onboardStore);

watch(isAuthenticated, async (newX) => {
    console.log(`x is ${newX}`)
})

const primaryButton: any = computed(() => {
    let buttonOptions = {
        label: 'Sign up',
        color: 'primary',
        to: '/onboard',
        variant: 'solid',
    }
    if (userStateReady.value) {
        if (hasAccountAccess.value) {
            buttonOptions.label = 'Account'
            buttonOptions.to = '/home'
        } else if (hasPartnerAccess.value) {
            buttonOptions.label = 'Dashboard'
            buttonOptions.to = '/dashboard'
        }
    }
    return buttonOptions
})

const secondaryButton: any = computed(() => {
    let buttonOptions = {
        label: 'Sign in',
        color: 'gray',
        to: '/login',
        variant: 'solid',
        show: true,
    }
    if (userStateReady.value) {
        if (hasAccountAccess.value || hasPartnerAccess.value || isMobile) {
            buttonOptions.show = false
        }
    }
    return buttonOptions
})

const accountItems = [
    [{
        label: 'Go to homepage',
        icon: 'i-heroicons-home',
        to: "/",
    }], [{
        label: 'Sign out',
        icon: 'i-heroicons-arrow-left-end-on-rectangle',
        click: async () => {
            await signOutUser();
        }
    }]
]
const links = [{
    label: 'For Patients',
    to: '/',
    description: 'Find local doctors who take global insurance'
}, {
    label: 'For Providers',
    to: '/for-providers',
    description: 'Provide a great patient experience, get paid quickly, and accept international insurance'
}]
</script>